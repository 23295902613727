<template>
  <div class="profile-removable">
    <div class="profile-removable__body" v-bind="$attrs"><slot /></div>
    <button class="profile-removable__btn" :class="{ 'profile-removable__btn--hidden': !removable }" @click="emit('remove')">
      <IconTrash class="icon" />
    </button>
  </div>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { IconTrash } from '@tabler/icons-vue'

const props = defineProps({
  removable: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['remove'])
</script>

<style scoped lang="scss">
.profile-removable {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  &__body {
    flex: 1;
  }
  &__btn {
    margin-top: 17px;
    flex-shrink: 0;
    color: var(--color-elements-secondary);
    transition: .2s color;
    &:hover {
      color: var(--color-error);
    }
    .icon {
      width: 24px;
      height: 24px;
    }
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
