<template>
  <div class="profile-field" :class="{ 'profile-field--single-col': singleCol, 'profile-field--inline': inline }">
    <div class="profile-field__container">
      <h3 v-if="!singleCol || name" class="profile-field__name">{{ name }}</h3>
      <div v-if="removable" class="profile-field__body">
        <ProfileRemovable v-bind="$attrs" @remove="emit('remove')"><slot /></ProfileRemovable>
      </div>
      <div v-else class="profile-field__body" v-bind="$attrs">
        <template v-if="!editing || isMobile">
          <slot />
          <EditButton v-if="editable" @click="editing = true" class="edit-button" />
        </template>
        <template v-else>
          <div class="profile-field__form">
            <slot name="form" />
          </div>
          <ProfileFormActions @cancel="onCancel" @submit="emit('submit')" :class="{ pending }" />
        </template>
      </div>
    </div>
    <slot name="after" />
  </div>
  <Teleport to="body">
    <MobilePopup :shown="isMobile && editing">
      <MobilePopupHeader :title="name" @close="onCancel" />
      <div class="horiz-container" v-bind="$attrs"><slot name="form" /></div>
      <Navbar>
        <BaseButton class="save-btn" :class="{ pending }" color-primary small @click="emit('submit')">Сохранить изменения</BaseButton>
      </Navbar>
    </MobilePopup>
  </Teleport>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import ProfileRemovable from '@/components/user/profile/forms/ProfileRemovable'
import EditButton from '@/components/user/profile/EditButton'
import ProfileFormActions from '@/components/user/profile/forms/ProfileFormActions'

const props = defineProps({
  name: String,
  removable: Boolean,
  singleCol: Boolean,
  inline: Boolean,
  editable: Boolean,
  pending: Boolean
})
const emit = defineEmits(['remove', 'cancel', 'submit'])

const { isMobile } = useBreakpoints()

const editing = ref(false)
defineExpose({ editing })

function onCancel () {
  editing.value = false
  emit('cancel')
}
</script>

<style scoped lang="scss">
.profile-field {
  &__container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: var(--color-elements-secondary);
    text-transform: uppercase;
    width: 158px;
    letter-spacing: 1px;
    @media (max-width: 920px) {
      width: auto;
    }
  }
  &__body {
    flex: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__form {
    flex: 1;
  }
  &--inline {
    .profile-field__container {
      align-items: center;
      justify-content: space-between;
      @media (max-width: 920px) {
        align-items: stretch;
        gap: 8px;
      }
    }
    .edit-button {
      margin-left: 0;
    }
    .profile-field__body {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      @media (max-width: 920px) {
        justify-content: flex-start;
        gap: 8px;
      }
    }
    .profile-field__name {
      display: block;
    }
  }
  &--single-col {
    .profile-field__container {
      flex-direction: column;
      align-items: stretch;
    }
    .profile-field__name {
      width: auto;
    }
  }
}

.horiz-container {
  padding-top: 24px;
  padding-bottom: 24px;
}
.save-btn {
  flex: 1;
}
</style>
